import fetch from "auth/FetchInterceptor";
import { UPDATE_USER } from "redux/constants/User";
import { UPDATE_NOTIFICATIONS } from "redux/constants/Menu";
import { UPDATE_PERMISSOES } from "redux/constants/Permissoes";
import store from "redux/store";

const MenuService = {};

/*
    Injeta na store do usuário os dados do menu
*/
// TODO: após clonar o skeleton, ajustar essa função
MenuService.populateMenuData = async function () {
	const menuData = await MenuService.getMenuData();

	store.dispatch({
		type: UPDATE_USER,
		name: menuData.user.nome,
		// isConsultorLider: menuData.isConsultorLider,
		// isConsultorLiderEmFormacao: menuData.isConsultorLiderEmFormacao,
		isRegional: menuData.isRegional,
		role: "Usuário",
		user: menuData.user.client_id,
	});

	store.dispatch({
		type: UPDATE_NOTIFICATIONS,
		produtosComErro: menuData.notificacoes.total_erros_produtos,
		consultoresComErro: menuData.notificacoes.total_erros_consultor,
		produtoresComErro: menuData.notificacoes.total_erros_produtores,
		pedidosComErro: menuData.notificacoes.total_erros_pedidos,
		pedidosSemVariacao:
			menuData.pedidosSemVariacao[0].total_pedidos_sem_variacao,
		pedidosComErroLead: menuData.notificacoes.total_erros_pedidos_lead,
		comissoesComErro: menuData.notificacoes.total_erros_comissoes,
		bonificacoesComErro: menuData.notificacoes.total_erros_bonificacao,
		enderecoAValidar: menuData.notificacoes.total_enderecos_a_validar,
		somaErrosSap:
			menuData.notificacoes.total_erros_produtos +
			menuData.notificacoes.total_erros_consultor +
			menuData.notificacoes.total_erros_produtores +
			menuData.notificacoes.total_erros_pedidos +
			menuData.notificacoes.total_erros_pedidos_lead +
			menuData.notificacoes.total_erros_comissoes +
			menuData.notificacoes.total_erros_bonificacao +
			menuData.notificacoes.total_enderecos_a_validar,
	});

	store.dispatch({
		type: UPDATE_PERMISSOES,
		permissoes: menuData.permissoes,
	});
};

// TODO: após clonar o skeleton, ajustar essa função
MenuService.getMenuData = function () {
	return fetch({
		url: "/v1/rest/menu",
		method: "get",
	});
};

export default MenuService;
