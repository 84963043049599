import {
	ADD_PRODUTO,
	EDIT_PRODUTO,
	REMOVE_PRODUTO /*SET_PRODUTOR*/,
} from "redux/constants/Carrinho";

const initCarrinho = {
	produtos: [
		{
			id_produto: 1,
			nome: "Produto 1",
			valor_unitario: 10,
			valor_unitario_original: 10,
			valor_parcela: 200,
			parcelas: 1,
			data_primeiro_pagamento: "03/06/2022",
			desconto: 10,
			quantidade: 20,
			unidade_medida: "Sacas",
			uniqid: 364536547,
			image_url:
				"https://agropos.com.br/wp-content/uploads/2021/01/Colheita-do-Algodao.jpg",
		},
	],
	id_produtor: null,
};

const carrinho = (state = initCarrinho, action) => {
	switch (action.type) {
		case ADD_PRODUTO:
			return {
				...state,
				produtos: [...state.produtos, { ...action.produto }],
			};
		case EDIT_PRODUTO:
			return {
				...state,
				produtos: state.produtos.map((produto) => {
					if (produto.id === action.produto.uniqid) {
						return { ...action.produto };
					}
					return produto;
				}),
			};
		case REMOVE_PRODUTO:
			return {
				...state,
				produtos: state.produtos.filter(
					(produto) => produto.uniqid !== action.uniqid,
				),
			};
		default:
			return state;
	}
};

export default carrinho;
