import { UPDATE_NOTIFICATIONS } from "redux/constants/Menu";

const initMenu = {
	produtosComErro: 0,
	consultoresComErro: 0,
	produtoresComErro: 0,
	pedidosComErro: 0,
	comissoesComErro: 0,
	bonificacoesComErro: 0,
	enderecoAValidar: 0,
	somaErrosSap: 0,
	pedidosSemVariacao: 0,
};

const menu = (state = initMenu, action) => {
	switch (action.type) {
		case UPDATE_NOTIFICATIONS:
			return {
				...state,
				produtosComErro: action.produtosComErro,
				consultoresComErro: action.consultoresComErro,
				produtoresComErro: action.produtoresComErro,
				pedidosComErro: action.pedidosComErro,
				pedidosComErroLead: action.pedidosComErroLead,
				comissoesComErro: action.comissoesComErro,
				somaErrosSap: action.somaErrosSap,
				bonificacoesComErro: action.bonificacoesComErro,
				enderecoAValidar: action.enderecoAValidar,
				pedidosSemVariacao: action.pedidosSemVariacao,
			};
		default:
			return state;
	}
};

export default menu;
