// TODO: após clonar o skeleton, ajustar os links de ambientes

const dev = {
	API_ENDPOINT_URL: "http://localhost:8090/tec/produce/api",
	PUBLIC_PREFIX_PATH: "",
	API_CORE_ENDPOINT_URL: "http://localhost:8090/tec/core/api",
};

const prod = {
	API_ENDPOINT_URL: "https://produce.dimo.com.br/tec/produce/api",
	PUBLIC_PREFIX_PATH: "",
	API_CORE_ENDPOINT_URL: "https://produce.dimo.com.br/tec/core/api",
};

const test = {
	API_ENDPOINT_URL:
		"https://homolog.dimo.com.br/sempre-produce-back/produce/api",
	PUBLIC_PREFIX_PATH: "/produce",
	API_CORE_ENDPOINT_URL:
		"https://homolog.dimo.com.br/sempre-produce-back/core/api",
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case "development":
			return dev;
		case "production":
			return prod;
		case "test":
			return test;
		default:
			return dev;
	}
};

export const env = getEnv();
