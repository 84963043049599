import React, { useEffect } from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
import {
	SIDE_NAV_WIDTH,
	SIDE_NAV_DARK,
	NAV_TYPE_SIDE,
} from "constants/ThemeConstant";
import { Scrollbars } from "react-custom-scrollbars";
import MenuContent from "./MenuContent";
import { LogoProduceSapSVG, ProduceLogoSmallSVG } from "../../assets/svg/icon";
import NavProfile from "./NavProfile";
import { toggleCollapsedNav } from "../../redux/actions/Theme";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const { Sider } = Layout;

export const SideNav = ({
	navCollapsed,
	sideNavTheme,
	routeInfo,
	hideGroupTitle,
	navigationConfig,
	toggleCollapsedNav,
	localization = true,
	isMobile,
	user,
}) => {
	const props = {
		navigationConfig,
		sideNavTheme,
		routeInfo,
		hideGroupTitle,
		localization,
		isMobile,
		user,
	};
	const corBackground = "transparent";

	useEffect(() => {
		toggleCollapsedNav(isMobile);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile]);

	return (
		<Sider
			className={`side-nav ${
				sideNavTheme === SIDE_NAV_DARK ? "side-nav-dark" : ""
			}`}
			width={SIDE_NAV_WIDTH}
			collapsed={navCollapsed}
		>
			<div
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					backgroundColor: corBackground,
				}}
			>
				<Scrollbars autoHide>
					<div style={{ flexGrow: 1 }}>
						<div
							style={{
								margin: 20,
								marginTop: 24,
								marginBottom: 24,
							}}
						>
							{!navCollapsed && (
								<div
									style={{ paddingRight: 6, display: "flex" }}
								>
									<div>
										<LogoProduceSapSVG />
									</div>
									<div
										style={{
											paddingTop: 10,
											marginLeft: 15,
											fontSize: "20px",
											cursor: "pointer",
										}}
									>
										<div
											style={{ color: "#1C355E" }}
											onClick={() =>
												toggleCollapsedNav(
													!navCollapsed,
												)
											}
										>
											{navCollapsed ? (
												<MenuUnfoldOutlined />
											) : (
												<MenuFoldOutlined />
											)}
										</div>
									</div>
								</div>
							)}

							{navCollapsed && (
								<div>
									<div>
										<ProduceLogoSmallSVG />
									</div>
									<div
										style={{
											paddingTop: 10,
											display: "flex",
											justifyContent: "center",
											fontSize: "20px",
											cursor: "pointer",
										}}
									>
										<div
											onClick={() =>
												toggleCollapsedNav(
													!navCollapsed,
												)
											}
										>
											{navCollapsed ? (
												<MenuUnfoldOutlined />
											) : (
												<MenuFoldOutlined />
											)}
										</div>
									</div>
								</div>
							)}
						</div>

						<div>
							<MenuContent
								type={NAV_TYPE_SIDE}
								{...props}
							/>
						</div>
					</div>
				</Scrollbars>

				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						marginTop: 10,
						marginBottom: 8,
					}}
				>
					<NavProfile />
				</div>
			</div>
		</Sider>
	);
};

const mapStateToProps = ({ theme, user }) => {
	const { navCollapsed, sideNavTheme } = theme;
	return { user: { ...user }, navCollapsed, sideNavTheme };
};

export default connect(mapStateToProps, { toggleCollapsedNav })(SideNav);
