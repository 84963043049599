import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import history from "../history";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { notification } from "antd";

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000,
});

// Config
const ENTRY_ROUTE = "#/auth/login";
const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
	(config) => {
		const jwtToken = localStorage.getItem(AUTH_TOKEN);

		if (jwtToken) {
			config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
		}

		if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
			history.push(ENTRY_ROUTE);
			window.location.reload();
		}

		return config;
	},
	(error) => {
		// Do something with request error here
		notification.error({
			message: "Error",
		});
		Promise.reject(error);
	},
);

// API respone interceptor
service.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		let notificationParam = {
			message: "",
		};

		if (!error.response) {
			notificationParam.message = "Erro inesperado";
			notification.error(notificationParam);
			return Promise.reject(error);
		}

		// Remove token and redirect
		if (error.response.status === 400) {
			notificationParam.message = "Erro inesperado";
		}

		if (error.response.status === 401) {
			notificationParam.message = "Falha na autenticação";
			notificationParam.description =
				error.response?.data?.message ?? "Erro inesperado";
			localStorage.removeItem(AUTH_TOKEN);
			notification.error(notificationParam);
			return Promise.reject(error);
		}

		if (
			error.response.status === 404 ||
			error.response.status === 403 ||
			error.response.status === 500
		) {
			if (error.response.data?.message) {
				notificationParam.message = error.response.data.message;
			} else if (error.response.data?.error) {
				notificationParam.message = error.response.data.error;
			} else {
				notificationParam.message = "Not Found";
			}
		}

		if (error.response.status === 508) {
			notificationParam.message = "Time Out";
		}

		notification.error(notificationParam);

		return Promise.reject(error);
	},
);

export default service;
