import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { TREINAMENTO_PREFIX_PATH } from "configs/AppConfig";
import JwtAuthService from "services/JwtAuthService";
import MenuService from "services/MenuService";

export const TreinamentoViews = () => {
	const populateMenu = async () => {
		try {
			if (JwtAuthService.isLogged()) {
				await MenuService.populateMenuData();
			}
		} catch (e) {}
		setInterval(() => {
			MenuService.populateMenuData();
		}, 30000);
	};

	useEffect(() => {
		populateMenu();
	}, []);

	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${TREINAMENTO_PREFIX_PATH}/dashboard`}
					component={lazy(() => import(`./dashboard`))}
				/>
				<Route
					path={`${TREINAMENTO_PREFIX_PATH}/submodulos/:id`}
					component={lazy(() => import(`./submodulos`))}
				/>
				<Route
					path={`${TREINAMENTO_PREFIX_PATH}/submodulos`}
					component={lazy(() => import(`./submodulos`))}
				/>
				<Redirect
					from={`${TREINAMENTO_PREFIX_PATH}`}
					to={`${TREINAMENTO_PREFIX_PATH}/dashboard`}
				/>
			</Switch>
		</Suspense>
	);
};

export default TreinamentoViews;
