import React from "react";
import { Menu, Avatar } from "antd";
import { connect } from "react-redux";
import { signOut } from "redux/actions/Auth";
import { ClearOutlined, EditOutlined, LogoutOutlined } from "@ant-design/icons";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import { Link } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import requestCacheService from "services/RequestCacheService";

export const NavProfile = ({ signOut, user, navCollapsed }) => {
	return (
		<div className="nav-profile-header" style={{ alignItems: "center" }}>
			<div className="d-flex">
				<Avatar
					style={{
						marginLeft: 10,
						color: "#0038B4",
						backgroundColor: "#f3f6fc",
						fontSize: 17,
						fontWeight: "bold",
					}}
				>
					{user?.name
						?.split(" ")
						.map((n) => n[0])
						.slice(0, 2)
						.join("")}
				</Avatar>
				<div
					className="pl-3"
					style={{
						marginRight: 10,
						marginLeft: !navCollapsed ? 0 : -15,
						marginTop: 5,
					}}
				>
					{!navCollapsed && <span>{user.name}</span>}
					<EllipsisDropdown
						placement={"topLeft"}
						menu={
							<Menu>
								<Menu.Item key="0">
									<div style={{ display: "flex" }}>
										<div style={{ flexGrow: 1 }}>
											<span>
												<Link
													to={
														`${APP_PREFIX_PATH}/edição de usuario/` +
														user.id
													}
												>
													Editar
												</Link>{" "}
											</span>
										</div>
										<div>
											<EditOutlined />
										</div>
									</div>
								</Menu.Item>

								<Menu.Divider />

								<Menu.Item
									key="2"
									onClick={() =>
										requestCacheService.limparCache()
									}
								>
									<div style={{ display: "flex" }}>
										<div style={{ flexGrow: 1 }}>
											<span>Limpar cache</span>
										</div>
										<div>
											<ClearOutlined />
										</div>
									</div>
								</Menu.Item>

								<Menu.Divider />

								<Menu.Item key="1" onClick={(e) => signOut()}>
									<div style={{ display: "flex" }}>
										<div style={{ flexGrow: 1 }}>
											<span>Sair</span>
										</div>
										<div>
											<LogoutOutlined />
										</div>
									</div>
								</Menu.Item>
							</Menu>
						}
					/>
					{!navCollapsed && (
						<div>
							<span className="text-muted">{user.role}</span>
							<br />
							<span className="text-muted">
								{user?.user?.substring(0, 25) +
									(user?.user?.length > 25 ? "…" : "")}
							</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({ user, theme }) => {
	const { navCollapsed, sideNavTheme } = theme;
	return { user: { ...user }, navCollapsed, sideNavTheme };
};

export default connect(mapStateToProps, { signOut })(NavProfile);
