export const styles = {
	container: {
		border: "none",
		height: "100vh",
	},
	container_politica: {
		padding: "20px",
	},

};
